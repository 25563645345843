<template>
  <v-app style="background: black;">
    <v-main>
      <Content />
    </v-main>
  </v-app>
</template>

<script>
import Content from "./components/Content";

export default {
  name: "App",
  components: {
    Content
  }
};
</script>

<style scoped>
* {
  color: white;
}
</style>
