<template>
  <div>
    <v-container id="firstContainer" class="py-12" :style="`background-image: url('${require('@/assets/fondo.jpg')}');`">
      <v-row>
        <v-col class="text-center mb-lg-12" cols="12">
          <span class="text-subtitle-2 text-lg-h5">
            <span class="font-weight-bold">Soluciones de mercado para los retos del feminismo</span> | Un evento impulsado por el Instituto Juan de Mariana y MICROWD.
          </span>
        </v-col>
  
        <v-col class="text-center mb-lg-12" cols="12">
          <span class="text-subtitle-1 text-lg-h4 font-weight-black">
            VIERNES 5 DE MARZO DE 2021 15:00 - 20:00
          </span>
        </v-col>
  
        <v-row class="px-3" justify="center">
          <v-col class="mb-md-12" cols="12" md="4">
            <span class="text-subtitle-1 text-lg-h6">
              Creemos que <span class="font-weight-black"> hombres y mujeres merecemos los mismos derechos y oportunidades.</span> Por eso, queremos dar voz a empresarios/as que desarrollan iniciativas comprometidas con este principio. Desarrollaremos diálogos rigurosos sobre feminismo y capitalismo, casos de éxito y ejemplos actuales.
            </span>
          </v-col>
          <v-col class="mb-md-12" cols="12" md="4">
            <span class="text-subtitle-1 text-lg-h6">
              <span class="font-weight-black">Participan:</span> Santiago Navajas, Irune Ariño, María Blanco, Alejandro de León, Elena Gómez del Pozuelo, Gema Montoya, María Pilar Rojas, Jessica Olivan, Andrea S. Paltzer, Alicia Rubi y Deirdre McCloskey. 
            </span>
          </v-col>
        </v-row>
  
        <v-row class="py-5" justify="center">
          <v-col class="text-center py-0" cols="12" md="4" xl="3">
            <v-btn
              class="btnConnect white--text text-subtitle-1 text-lg-h5 font-weight-bold mb-5"
              rounded
              href="./programa_feminismo.png"
              target="_blank">Descarga el programa
            </v-btn> <br>
          </v-col>
          <v-col class="text-center py-0" cols="12" md="4" xl="3">
            <v-btn
              class="btnConnect white--text text-subtitle-1 text-lg-h5 font-weight-bold"
              color="myfluor"
              rounded
              href="https://zoom.us/meeting/register/tJEvcu2vqTgpGtJSDmDGbzeqoiX17SU99Gxm"
              target="_blank">¡Conéctate!
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-container>

    <v-container id="secondContainer" class="py-12">
      <v-row class="py-md-10">
        <v-col class="text-center mb-12" cols="12">
          <span class="text-h5 text-lg-h3 font-weight-black">
            PARTICIPANTES:
          </span>
        </v-col>

        <v-col
          v-for="n in 5"
          :key="n"
          class="d-flex child-flex pb-12"
          cols="12"
          md="3"          
        >
          <v-card color="transparent" elevation="0">
            <v-img
              :src="require(`@/assets/participants/participant${n}.png`)"
              aspect-ratio="1"
              height="600"
              class="imgGalery"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title class="white--text text-h5">{{ partipantsNames[n - 1] }}</v-card-title>
          </v-card>
        </v-col>

        <v-col class="contentLogos justify-center py-5" align-self="center" cols="12">
          <a href="https://www.juandemariana.org/" target="_blank">
            <v-img class="logo_jdm mr-10" src="@/assets/logo_jdm.png"></v-img>
          </a>
          <a href="https://www.microwd.es/" target="_blank">
            <v-img class="logo_microwd" src="@/assets/logo_microwd.png"></v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: "Content",
    data: () => ({
      partipantsNames: [
        "Deirdre McCloskey",
        "Santiago Navajas",
        "María Blanco",
        "Alejandro de León",
        "Irune Ariño"
      ]
    })
  };
</script>

<style scoped>
  #firstContainer, #secondContainer {
    max-width: 100%;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #secondContainer {
    background: #5a539d;
  }

  span {
    line-height: 1.5rem !important;
  }

  .contentLogos {
    display: flex;
    align-items: center;
  }

  .logo_jdm,
  .logo_microwd {
    vertical-align: middle;
  }

  .logo_jdm {
    max-width: 150px;
  }

  .logo_microwd {
    max-width: 200px;
  }

  .btnConnect {
    padding: 30px 100px !important;
    background: rgb(149,119,220);
    background: linear-gradient(90deg, rgba(149,119,220,1) 50%, rgba(17,149,208,1) 100%);
    text-transform: none;
  }

  .imgGalery {
    border: 2px solid white;
  }

  /* ----------MEDIA QUERYS---------- */
  @media (max-width: 1264px) {
    .btnConnect {
      padding: 20px 35px !important;
    }

    .logo_jdm {
      max-width: 100px;
    }

    .logo_microwd {
      max-width: 150px;
    }

    .contentLogos {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
</style>
